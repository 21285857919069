import mapValues from 'lodash/mapValues';
import {
  SHOW_FILTERS,
  HIDE_FILTERS,
  HIDE_ALL_FILTERS,
  REQUEST_FILTERED_RESULTS,
  RECEIVE_FILTERED_RESULTS,
  PARCEL_FILTER_SELECTED,
  PARCEL_FILTERS_RESET,
  ENABLE_FILTER_DATATYPE,
  ENABLE_RESET_MODE,
  DISABLE_RESET_MODE,
  ENABLE_RESET_ALL_MODAL,
  ENABLE_RESET_FILTER_MODAL,
  DISABLE_FILTER_DATATYPE,
  DISABLE_ALL_FILTERS,
  DISABLE_ALL_FILTER_DATATYPES,
  SELECT_SAVEDSEARCH,
  SELECT_ALERT_RECORD,
  LIST_ORDER_CHANGE,
  ENABLE_LIST_MODE,
  DISABLE_LIST_MODE,
  SELECT_MYLIST,
  DISABLE_LIST_MODE_ALL_DATATYPES,
  ENABLE_LEASE_FILTERS,
  ENABLE_LEASE_EXPIRATION_FILTERS,
  ENABLE_LISTING_FILTERS,
  ENABLE_SALE_FILTERS,
  PARCEL_DATA_LOADED,
  PARCEL_DATA_FILTERED,
  REQUEST_SAVEDSEARCH_EXPORT,
  SAVEDSEARCH_EXPORT_ERROR,
  SAVEDSEARCH_EXPORT_COMPLETE,
  SAVEDSEARCH_EXPORT_SUCCESS
} from '../../constants/actionTypes';
import datatypes from '../../common/datatypes';

const defaultFilters = {
  availability: {
    dealStatus: ['Available'],
    areaAndMinDivisibleArea: { andOrChecked: 'AND', area: {}, minDivisibleArea: {} }
  },
  tim: { status: ['Active'] }
};

const initialState = mapValues(datatypes, ({ key }) => {
  return {
    isOpen: false,
    isSearching: false,
    isEnabled: key === 'property',
    filters: defaultFilters[key] || {},
    query: {},
    results: []
  };
});

initialState.parcel = {
  isOpen: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_FILTERS:
      return {
        ...state,
        [action.datatype]: { ...state[action.datatype], isOpen: true }
      };

    case HIDE_FILTERS:
      return {
        ...state,
        [action.datatype]: { ...state[action.datatype], isOpen: false }
      };

    case HIDE_ALL_FILTERS:
      const nextState = {
        ...state,
        parcel: {
          ...state.parcel,
          isOpen: false
        }
      };

      for (const key in datatypes) {
        nextState[key] = { ...state[key], isOpen: false };
      }

      return nextState;

    case SELECT_SAVEDSEARCH:
      return mapValues(state, (val, dataType) => {
        if (action.savedSearch.enabledDataTypes.includes(dataType)) {
          const hasPolygon = JSON
            .stringify((action.savedSearch.query || {})[dataType] || {})
            .indexOf('geo_polygon') > -1;
          return {
            ...val,
            filters: (action.savedSearch.filters || {})[dataType] || {},
            isEnabled: (action.savedSearch.enabledDataTypes && action.savedSearch.enabledDataTypes.includes(dataType)),
            query: hasPolygon ? action.savedSearch.query[dataType] : {},
            results: []
          };
        }
        return {
          isOpen: false,
          isSearching: false,
          isEnabled: false,
          filters: defaultFilters[dataType] || {},
          query: {},
          results: [],
        };
      });

    case ENABLE_RESET_MODE:
      return {
        ...state,
        resetOpen: !state.resetOpen,
        resetAllMenu: false,
        resetFilterMenu: false
      };
    
    case DISABLE_RESET_MODE:
      return {
        ...state,
        resetAllMenu: false,
        resetFilterMenu: false
      };

    case ENABLE_RESET_ALL_MODAL:
      return {
        ...state,
        resetAllMenu: true,
        resetFilterMenu: false
      };

    case ENABLE_RESET_FILTER_MODAL:
      return {
        ...state,
        resetFilterMenu: true,
        resetAllMenu: false
      };

    case ENABLE_FILTER_DATATYPE:
      return {
        ...state,
        [action.datatype]: { ...state[action.datatype], isEnabled: true }
      };

    case ENABLE_LEASE_FILTERS:
      const leaseIds = action.data.map((item) => item.id);
      return {
        ...state,
        [action.datatype]: { ...state[action.datatype], filters: { _id: leaseIds }, }
      };

    case ENABLE_LEASE_EXPIRATION_FILTERS:
      const ids = action.data.map((item) => item.id);
      return {
        ...state,
        [action.datatype]: { ...state[action.datatype], filters: { _id: ids }, }
      };
    
    case ENABLE_LISTING_FILTERS:
      const listingIds = action.data.map((item) => item.id);
      return {
        ...state,
        [action.datatype]: { ...state[action.datatype], filters: { _id: listingIds }, }
      };
    
    case ENABLE_SALE_FILTERS:
      const saleIds = action.data.map((item) => item.id);
      return {
        ...state,
        [action.datatype]: { ...state[action.datatype], filters: { _id: saleIds }, }
      };

    case DISABLE_FILTER_DATATYPE:
      return {
        ...state,
        [action.datatype]: { ...state[action.datatype], isEnabled: false, results: [] }
      };

    case DISABLE_ALL_FILTERS:
      const clearedFilterState = {
        ...state,
        resetFilterMenu: false,
        resetOpen: false
      };

      for (const key in datatypes) {
        if (key === 'availability') {
          clearedFilterState[key] = {
            ...state[key],
            filters: { areaAndMinDivisibleArea: { andOrChecked: 'AND', area: {}, minDivisibleArea: {} } },
            listMode: false
          };
        } else {
          clearedFilterState[key] = {
            ...state[key],
            filters: {},
            listMode: false
          };
        }
      }

      clearedFilterState.parcel.resetDate = Date.now();

      return clearedFilterState;

    case DISABLE_ALL_FILTER_DATATYPES:
      const clearedState = {
        ...state,
        ...state.parcel.filterCount = 0,
        ...state.parcel.filters = {},
        resetAllMenu: false,
        resetOpen: false,
      };

      for (const key in datatypes) {
        if (key === 'availability') {
          clearedState[key] = {
            ...state[key],
            isOpen: false,
            isSearching: false,
            isEnabled: key === 'property',
            filters: { areaAndMinDivisibleArea: { andOrChecked: 'AND', area: {}, minDivisibleArea: {} } },
            query: {},
            results: [],
            listMode: false
          };
        } else {
          clearedState[key] = {
            ...state[key],
            isOpen: false,
            isSearching: false,
            isEnabled: key === 'property',
            filters: {},
            query: {},
            results: [],
            listMode: false
          };
        }
      }

      return clearedState;

    case REQUEST_FILTERED_RESULTS:
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          isSearching: true,
          query: action.query,
          filters: action.filters
        }
      };

    case RECEIVE_FILTERED_RESULTS:
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          results: action.results,
          isSearching: false
        }
      };

    case PARCEL_FILTER_SELECTED:
      return {
        ...state,
        parcel: {
          ...state.parcel,
          filters: {
            ...state.parcel.filters, 
            ...action.filters
          }
        }
      };

    case PARCEL_FILTERS_RESET:
      return {
        ...state,
        parcel: {
          ...state.parcel,
          filters: {}
        }
      };

    case LIST_ORDER_CHANGE:
      let { key, sortKey, direction } = action;
      if (!direction) {
        key = null;
        sortKey = null;
        direction = null;
      }
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          orderBy: { key, sortKey, direction }
        }
      };

    case ENABLE_LIST_MODE:
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          listMode: true
        }
      };

    case DISABLE_LIST_MODE:
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          filters: {},
          listMode: false
        }
      };

    case DISABLE_LIST_MODE_ALL_DATATYPES:
      const clearedListModeState = {
        ...state,
      };
  
      for (const key in datatypes) {
        clearedListModeState[key] = {
          ...state[key],
          listMode: false
        };
      }
  
      return clearedListModeState;

    case SELECT_MYLIST:
      const { title = '', items = [] } = action.myList;
      return {
        ...state,
        [action.myList.datatype]: {
          ...state[action.myList.datatype],
          filters: {
            _id: items
          },
          listTitle: title,
          listCount: items.length
        }
      };

    case SELECT_ALERT_RECORD:
      return mapValues(state, (val, dataType) => {
        if (action.alert.datatype.includes(dataType)) {
          const hasPolygon = JSON
            .stringify((action.savedSearch.query || {})[dataType] || {})
            .indexOf('geo_polygon') > -1;
          return {
            ...val,
            filters: {
              ...(action.savedSearch.filters || {})[dataType] || {},
              _id: action.alert.items
            },
            isEnabled: (action.alert.datatype && action.alert.datatype.includes(dataType)),
            query: hasPolygon ? action.savedSearch.query[dataType] : {}
          };
        }
        return {
          isOpen: false,
          isSearching: false,
          isEnabled: false,
          filters: defaultFilters[dataType] || {},
          query: {},
          results: [],
        };
      });

    case PARCEL_DATA_LOADED:
      return {
        ...state,
        parcel: {
          ...state.parcel,
          unfilteredData: action.data,
          unfilteredDataTime: Date.now()
        }
      };

    case PARCEL_DATA_FILTERED:
      return {
        ...state,
        parcel: {
          ...state.parcel,
          filterCount: action.filterCount,
          filteredData: action.data,
          filteredDataHash: action.hash
        }
      };

    case REQUEST_SAVEDSEARCH_EXPORT:
      return {
        ...state,
        isSearching: true
      };
    case SAVEDSEARCH_EXPORT_SUCCESS:
      return {
        ...state,
        [action.datatype]: { 
          ...state[action.datatype],
          isEnabled: true,
          query: action.query,
          filter: action.filter,
          results: action.data.hits.hits || [],
          isSearching: false
        }

      };
    case SAVEDSEARCH_EXPORT_COMPLETE:
      return {
        ...state,
        isSearching: false
      };
    case SAVEDSEARCH_EXPORT_ERROR:
      return {
        ...state,
        isSearching: false,
        savedSearchExportError: action.data
      };
  }

  return state;
}
