export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const RESET_APP_STATE = 'RESET_APP_STATE';

export const GUEST_LOGIN_REQUEST = 'GUEST_LOGIN_REQUEST';
export const GUEST_LOGIN_ERROR = 'GUEST_LOGIN_ERROR';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const CURRENT_USER_FETCH_REQUEST = 'CURRENT_USER_FETCH_REQUEST';
export const CURRENT_USER_FETCH_SUCCESS = 'CURRENT_USER_FETCH_SUCCESS';
export const CURRENT_USER_FETCH_ERROR = 'CURRENT_USER_FETCH_ERROR';
export const CURRENT_USER_UPDATE_REQUEST = 'CURRENT_USER_UPDATE_REQUEST';
export const CURRENT_USER_UPDATE_SUCCESS = 'CURRENT_USER_UPDATE_SUCCESS';
export const CURRENT_USER_UPDATE_ERROR = 'CURRENT_USER_UPDATE_ERROR';
export const CURATE_USER_FETCH_REQUEST = 'CURATE_USER_FETCH_REQUEST';
export const CURATE_USER_FETCH_SUCCESS = 'CURATE_USER_FETCH_SUCCESS';
export const CURATE_USER_FETCH_ERROR = 'CURATE_USER_FETCH_ERROR';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

export const ONBOARDING_NEXT_STEP = 'ONBOARDING_NEXT_STEP';
export const ONBOARDING_PREVIOUS_STEP = 'ONBOARDING_PREVIOUS_STEP';
export const ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE';

export const FAVORITES_LIST_REQUEST = 'FAVORITES_LIST_REQUEST';
export const FAVORITES_LIST_SUCCESS = 'FAVORITES_LIST_SUCCESS';
export const FAVORITES_LIST_ERROR = 'FAVORITES_LIST_ERROR';
export const FEED_LIST_REQUEST = 'FEED_LIST_REQUEST';
export const FEED_LIST_SUCCESS = 'FEED_LIST_SUCCESS';
export const FEED_LIST_ERROR = 'FEED_LIST_ERROR';
export const FEED_LIST_UPDATE_OFFSET = 'FEED_LIST_UPDATE_OFFSET';
export const FEED_ITEM_EXPAND_TOGGLE = 'FEED_ITEM_EXPAND_TOGGLE';
export const FEED_ITEM_HYDRATE_REQUEST = 'FEED_ITEM_HYDRATE_REQUEST';
export const FEED_ITEM_HYDRATE_SUCCESS = 'FEED_ITEM_HYDRATE_SUCCESS';
export const FEED_ITEM_HYDRATE_ERROR = 'FEED_ITEM_HYDRATE_ERROR';
export const FEED_ADD_LOCATION_REQUEST = 'FEED_ADD_LOCATION_REQUEST';
export const FEED_ADD_LOCATION_SUCCESS = 'FEED_ADD_LOCATION_SUCCESS';
export const FEED_ADD_LOCATION_ERROR = 'FEED_ADD_LOCATION_ERROR';
export const FEED_REMOVE_LOCATION_REQUEST = 'FEED_REMOVE_LOCATION_REQUEST';
export const FEED_REMOVE_LOCATION_SUCCESS = 'FEED_REMOVE_LOCATION_SUCCESS';
export const FEED_REMOVE_LOCATION_ERROR = 'FEED_REMOVE_LOCATION_ERROR';
export const FEED_ITEM_SHARE_SHOW = 'FEED_ITEM_SHARE_SHOW';
export const FEED_ITEM_SHARE_HIDE = 'FEED_ITEM_SHARE_HIDE';
export const FEED_ITEM_SHARE_SEND_REQUEST = 'FEED_ITEM_SHARE_SEND_REQUEST';
export const FEED_ITEM_SHARE_SEND_SUCCESS = 'FEED_ITEM_SHARE_SEND_SUCCESS';
export const FEED_ITEM_SHARE_SEND_ERROR = 'FEED_ITEM_SHARE_SEND_ERROR';
export const FEED_ITEM_FAVORITE_CREATE_REQUEST = 'FEED_ITEM_FAVORITE_CREATE_REQUEST';
export const FEED_ITEM_FAVORITE_CREATE_SUCCESS = 'FEED_ITEM_FAVORITE_CREATE_SUCCESS';
export const FEED_ITEM_FAVORITE_CREATE_ERROR = 'FEED_ITEM_FAVORITE_CREATE_ERROR';
export const FEED_ITEM_FAVORITE_REMOVE_REQUEST = 'FEED_ITEM_FAVORITE_REMOVE_REQUEST';
export const FEED_ITEM_FAVORITE_REMOVE_SUCCESS = 'FEED_ITEM_FAVORITE_REMOVE_SUCCESS';
export const FEED_ITEM_FAVORITE_REMOVE_ERROR = 'FEED_ITEM_FAVORITE_REMOVE_ERROR';
export const FEED_SUMMARY_LIST_REQUEST = 'FEED_SUMMARY_LIST_REQUEST';
export const FEED_SUMMARY_LIST_SUCCESS = 'FEED_SUMMARY_LIST_SUCCESS';
export const FEED_SUMMARY_LIST_ERROR = 'FEED_SUMMARY_LIST_ERROR';

export const SHOW_FILTERS = 'SHOW_FILTERS';
export const HIDE_FILTERS = 'HIDE_FILTERS';
export const HIDE_ALL_FILTERS = 'HIDE_ALL_FILTERS';
export const PARCEL_FILTER_SELECTED = 'PARCEL_FILTER_SELECTED';
export const PARCEL_FILTERS_RESET = 'PARCEL_FILTERS_RESET';
export const REQUEST_FILTERED_RESULTS = 'REQUEST_FILTERED_RESULTS';
export const RECEIVE_FILTERED_RESULTS = 'RECEIVE_FILTERED_RESULTS';
export const FILTERED_QUERY_WITH_IDS_ERROR = 'FILTERED_QUERY_WITH_IDS_ERROR';
export const FILTERED_QUERY_WITH_IDS_REQUEST = 'FILTERED_QUERY_WITH_IDS_REQUEST';
export const FILTERED_QUERY_WITH_IDS_SUCCESS = 'FILTERED_QUERY_WITH_IDS_SUCCESS';
export const FILTERED_QUERY_WITH_IDS_QUERYING_COMPLETE = 'FILTERED_QUERY_WITH_IDS_QUERYING_COMPLETE';
export const ENABLE_FILTER_DATATYPE = 'ENABLE_FILTER_DATATYPE';
export const DISABLE_FILTER_DATATYPE = 'DISABLE_FILTER_DATATYPE';
export const ENABLE_RESET_MODE = 'ENABLE_RESET_MODE';
export const DISABLE_RESET_MODE = 'DISABLE_RESET_MODE';
export const ENABLE_RESET_FILTER_MODAL = 'ENABLE_RESET_FIlER_MODAL';
export const ENABLE_RESET_ALL_MODAL = 'ENABLE_RESET_ALL_MODAL';
export const ENABLE_LEASE_EXPIRATION_FILTERS = 'ENABLE_LEASE_EXPIRATION_FILTERS';
export const ENABLE_LEASE_FILTERS = 'ENABLE_LEASE_FILTERS';
export const ENABLE_LISTING_FILTERS = 'ENABLE_LISTING_FILTERS';
export const ENABLE_SALE_FILTERS = 'ENABLE_SALE_FILTERS';
export const DISABLE_ALL_FILTERS = 'DISABLE_ALL_FILTERS';
export const DISABLE_ALL_FILTER_DATATYPES = 'DISABLE_ALL_FILTER_DATATYPE';
export const UPDATE_MAP_PINS = 'UPDATE_MAP_PINS';
export const ENABLE_SELECT_MODE = 'ENABLE_SELECT_MODE';
export const DISABLE_SELECT_MODE = 'DISABLE_SELECT_MODE';
export const SELECT_SEARCH_RESULT = 'SELECT_SEARCH_RESULT';
export const DESELECT_SEARCH_RESULT = 'DESELECT_SEARCH_RESULT';
export const SET_SELECTION_ANCHOR = 'SET_SELECTION_ANCHOR';
export const LIST_ORDER_CHANGE = 'LIST_ORDER_CHANGE';
export const SELECT_POPUP = 'SELECT_POPUP';
export const CLEAR_POPUP = 'CLEAR_POPUP';
export const SET_LIST_MOUSEOVER = 'SET_LIST_MOUSEOVER';
export const SET_MAP_POLYGONS = 'SET_MAP_POLYGONS';
export const CLEAR_MAP_POLYGONS = 'CLEAR_MAP_POLYGONS';
export const SELECT_ALL_SEARCH_RESULTS = 'SELECT_ALL_SEARCH_RESULTS';
export const CLEAR_SELECTED_RESULTS = 'CLEAR_SELECTED_RESULTS';
export const CLEAR_SELECTED_RESULTS_ALL_DATATYPES = 'CLEAR_SELECTED_RESULTS_ALL_DATATYPES';
export const FETCH_POPUP_CONTENT_REQUEST = 'FETCH_POPUP_CONTENT_REQUEST';
export const FETCH_POPUP_CONTENT_SUCCESS = 'FETCH_POPUP_CONTENT_SUCCESS';
export const FETCH_POPUP_CONTENT_ERROR = 'FETCH_POPUP_CONTENT_ERROR';
export const FETCH_FEATURE_CONTENT_REQUEST = 'FETCH_FEATURE_CONTENT_REQUEST';
export const FETCH_DEMO_CONTENT_REQUEST = 'FETCH_DEMO_CONTENT_REQUEST';
export const FETCH_FEATURE_META_REQUEST = 'FETCH_FEATURE_META_REQUEST';
export const FETCH_DEMO_CONTENT_SUCCESS = 'FETCH_DEMO_CONTENT_SUCCESS';
export const FETCH_FEATURE_CONTENT_SUCCESS = 'FETCH_FEATURE_CONTENT_SUCCESS';
export const FETCH_FEATURE_META_SUCCESS = 'FETCH_FEATURE_META_SUCCESS';
export const FETCH_FEATURE_CONTENT_ERROR = 'FETCH_FEATURE_CONTENT_ERROR';
export const TOGGLE_SHOW_SELECTED = 'TOGGLE_SHOW_SELECTED';
export const SET_SOURCE_FILTER_SCHEMA = 'SET_SOURCE_FILTER_SCHEMA';
export const UPDATE_MAP_BOUNDS = 'MAP_BOUNDS_UPDATED';
export const TOGGLE_SHARE_SEARCH_DIALOG = 'TOGGLE_SHARE_SEARCH_DIALOG';
export const SITEADVISOR_SESSION_REQUEST = 'SITEADVISOR_SESSION_REQUEST';
export const SITEADVISOR_SESSION_SUCCESS = 'SITEADVISOR_SESSION_SUCCESS';
export const SITEADVISOR_SESSION_ERROR = 'SITEADVISOR_SESSION_ERROR';
export const CLEAR_LIST_ERROR = 'CLEAR_LIST_ERROR';
export const REMOVE_POI = 'REMOVE_POI';

export const SELECT_SAVEDSEARCH = 'SELECT_SAVEDSEARCH';
export const CLEAR_SELECTED_SAVEDSEARCH = 'CLEAR_SELECTED_SAVEDSEARCH';
export const CREATED_SAVEDSEARCH = 'CREATED_SAVEDSEARCH';
export const DELETED_SAVEDSEARCH = 'DELETED_SAVEDSEARCH';
export const UPDATED_SAVEDSEARCH = 'UPDATED_SAVEDSEARCH';
export const LOAD_RECENT_SAVEDSEARCHES_REQUEST = 'LOAD_RECENT_SAVEDSEARCHES_REQUEST';
export const LOAD_RECENT_SAVEDSEARCHES_SUCCESS = 'LOAD_RECENT_SAVEDSEARCHES_SUCCESS';
export const LOAD_RECENT_SAVEDSEARCHES_FAILURE = 'LOAD_RECENT_SAVEDSEARCHES_FAILURE';
export const REQUEST_SAVEDSEARCH_EXPORT = 'REQUEST_SAVEDSEARCH_EXPORT';
export const SAVEDSEARCH_EXPORT_SUCCESS = 'SAVEDSEARCH_EXPORT_SUCCESS';
export const SAVEDSEARCH_EXPORT_ERROR = 'SAVEDSEARCH_EXPORT_ERROR';
export const SAVEDSEARCH_EXPORT_COMPLETE = 'SAVEDSEARCH_EXPORT_COMPLETE';

export const SELECT_MYLIST = 'SELECT_MYLIST';
export const CLEAR_SELECTED_MYLIST = 'CLEAR_SELECTED_MYLIST';
export const CREATED_MYLIST = 'CREATED_MYLIST';
export const DELETED_MYLIST = 'DELETED_MYLIST';
export const UPDATED_MYLIST = 'UPDATED_MYLIST';
export const LOAD_RECENT_MYLISTS_REQUEST = 'LOAD_RECENT_MYLISTS_REQUEST';
export const LOAD_RECENT_MYLISTS_SUCCESS = 'LOAD_RECENT_MYLISTS_SUCCESS';
export const LOAD_RECENT_MYLISTS_FAILURE = 'LOAD_RECENT_MYLISTS_FAILURE';

export const CLEAR_SELECTED_MYALERT = 'CLEAR_SELECTED_MYALERT';
export const UPDATED_MYALERT = 'UPDATED_MYALERT';
export const LOAD_RECENT_MYALERTS_REQUEST = 'LOAD_RECENT_MYALERTS_REQUEST';
export const LOAD_RECENT_MYALERTS_SUCCESS = 'LOAD_RECENT_MYALERTS_SUCCESS';
export const LOAD_RECENT_MYALERTS_FAILURE = 'LOAD_RECENT_MYALERTS_FAILURE';
export const UPDATE_MYALERTS_REQUEST = 'UPDATE_MYALERTS_REQUEST';
export const UPDATE_MYALERTS_SUCCESS = 'UPDATE_MYALERTS_SUCCESS';
export const UPDATE_MYALERTS_FAILURE = 'UPDATE_MYALERTS_FAILURE';

export const DETAIL_PROPERTY_FETCH_REQUEST = 'DETAIL_PROPERTY_FETCH_REQUEST';
export const DETAIL_PROPERTY_FETCH_SUCCESS = 'DETAIL_PROPERTY_FETCH_SUCCESS';
export const DETAIL_PROPERTY_FETCH_ERROR = 'DETAIL_PROPERTY_FETCH_ERROR';
export const DETAIL_PROPERTY_CLEAR = 'DETAIL_PROPERTY_CLEAR';
export const DETAIL_TIM_FETCH_REQUEST = 'DETAIL_TIM_FETCH_REQUEST';
export const DETAIL_TIM_FETCH_SUCCESS = 'DETAIL_TIM_FETCH_SUCCESS';
export const DETAIL_TIM_FETCH_ERROR = 'DETAIL_TIM_FETCH_ERROR';
export const DETAIL_TIM_CLEAR = 'DETAIL_TIM_CLEAR';
export const DETAIL_ALL_NEARBY_FETCH_REQUEST = 'DETAIL_ALL_NEARBY_FETCH_REQUEST';
export const DETAIL_ALL_NEARBY_FETCH_SUCCESS = 'DETAIL_ALL_NEARBY_FETCH_SUCCESS';
export const DETAIL_ALL_NEARBY_FETCH_ERROR = 'DETAIL_ALL_NEARBY_FETCH_ERROR';
export const DETAIL_COMMENT_SAVE_REQUEST = 'DETAIL_COMMENT_SAVE_REQUEST';
export const DETAIL_COMMENT_SAVE_SUCCESS = 'DETAIL_COMMENT_SAVE_SUCCESS';
export const DETAIL_COMMENT_SAVE_ERROR = 'DETAIL_COMMENT_SAVE_ERROR';
export const DETAIL_COMMENT_DELETE_REQUEST = 'DETAIL_COMMENT_DELETE_REQUEST';
export const DETAIL_COMMENT_DELETE_SUCCESS = 'DETAIL_COMMENT_DELETE_SUCCESS';
export const DETAIL_COMMENT_DELETE_ERROR = 'DETAIL_COMMENT_DELETE_ERROR';
export const DETAIL_COMMENTS_FETCH_REQUEST = 'DETAIL_COMMENTS_FETCH_REQUEST';
export const DETAIL_COMMENTS_FETCH_SUCCESS = 'DETAIL_COMMENTS_FETCH_SUCCESS';
export const DETAIL_COMMENTS_FETCH_ERROR = 'DETAIL_COMMENTS_FETCH_ERROR';
export const DETAIL_FILE_SAVE_REQUEST = 'DETAIL_FILE_SAVE_REQUEST';
export const DETAIL_FILE_SAVE_SUCCESS = 'DETAIL_FILE_SAVE_SUCCESS';
export const DETAIL_FILE_SAVE_ERROR = 'DETAIL_FILE_SAVE_ERROR';
export const DETAIL_FILE_DELETE_REQUEST = 'DETAIL_FILE_DELETE_REQUEST';
export const DETAIL_FILE_DELETE_SUCCESS = 'DETAIL_FILE_DELETE_SUCCESS';
export const DETAIL_FILE_DELETE_ERROR = 'DETAIL_FILE_DELETE_ERROR';
export const DETAIL_FILES_FETCH_REQUEST = 'DETAIL_FILES_FETCH_REQUEST';
export const DETAIL_FILES_FETCH_SUCCESS = 'DETAIL_FILES_FETCH_SUCCESS';
export const DETAIL_FILES_FETCH_ERROR = 'DETAIL_FILES_FETCH_ERROR';
export const DETAIL_SHOW_PROBLEM_REPORTER = 'DETAIL_SHOW_PROBLEM_REPORTER';
export const DETAIL_HIDE_PROBLEM_REPORTER = 'DETAIL_HIDE_PROBLEM_REPORTER';
export const DETAIL_REPORT_PROBLEM_REQUEST = 'DETAIL_REPORT_PROBLEM_REQUEST';
export const DETAIL_REPORT_PROBLEM_SUCCESS = 'DETAIL_REPORT_PROBLEM_SUCCESS';
export const DETAIL_REPORT_PROBLEM_ERROR = 'DETAIL_REPORT_PROBLEM_ERROR';
export const DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_REQUEST = 'DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_REQUEST';
export const DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_SUCCESS = 'DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_SUCCESS';
export const DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_ERROR = 'DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_ERROR';
export const DETAIL_DEMOGRAPHICS_REPORT_DOWNLOAD_REQUEST = 'DETAIL_DEMOGRAPHICS_REPORT_DOWNLOAD_REQUEST';
export const DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_SUCCESS = 'DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_SUCCESS';
export const DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_ERROR = 'DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_ERROR';
export const DETAIL_DEMOGRAPHIC_REPORT_ENABLE_VIEW = 'DETAIL_DEMOGRAPHIC_REPORT_ENABLE_VIEW';
export const DETAIL_DEMOGRAPHIC_REPORT_DISABLE_VIEW = 'DETAIL_DEMOGRAPHIC_REPORT_DISABLE_VIEW';

export const REPORT_GENERATE_REQUEST = 'REPORT_GENERATE_REQUEST';
export const REPORT_GENERATE_INITIATED = 'REPORT_GENERATE_INITIATED';
export const REPORT_GENERATE_SUCCESS = 'REPORT_GENERATE_SUCCESS';
export const REPORT_GENERATE_ERROR = 'REPORT_GENERATE_ERROR';
export const DISMISS_REPORT_ERROR = 'DISMISS_REPORT_ERROR';
export const REPORT_PREVIEW_REQUEST = 'REPORT_PREVIEW_REQUEST';
export const REPORT_PREVIEW_SUCCESS = 'REPORT_PREVIEW_SUCCESS';
export const REPORT_PREVIEW_ERROR = 'REPORT_PREVIEW_ERROR';
export const REPORT_SINGLE_GENERATE_REQUEST = 'REPORT_SINGLE_GENERATE_REQUEST';
export const REPORT_SINGLE_GENERATE_SUCCESS = 'REPORT_SINGLE_GENERATE_SUCCESS';
export const REPORT_SINGLE_GENERATE_ERROR = 'REPORT_SINGLE_GENERATE_ERROR';

export const ANALYSIS_REPORT_REQUEST = 'ANALYSIS_REPORT_REQUEST';
export const ANALYSIS_REPORT_SUCCESS = 'ANALYSIS_REPORT_SUCCESS';
export const ANALYSIS_REPORT_ERROR = 'ANALYSIS_REPORT_ERROR';
export const ANALYSIS_REPORT_ERROR_DISMISSED = 'ANALYSIS_REPORT_ERROR_DISMISSED';

export const NOTIFICATIONS_CLOSE = 'NOTIFICATIONS_CLOSE';

export const MY_LISTS_FETCH_REQUEST = 'MY_LISTS_FETCH_REQUEST';
export const MY_LISTS_FETCH_TRANSLATE_SUCCESS = 'MY_LISTS_FETCH_TRANSLATE_REQUEST_SUCCESS';
export const MY_LISTS_FETCH_ERROR = 'MY_LISTS_FETCH_ERROR';
export const MY_LISTS_UPDATE_ERROR = 'MY_LISTS_UPDATE_ERROR';
export const MY_LISTS_UPDATE_REQUEST = 'MY_LISTS_UPDATE_REQUEST';

export const ENABLE_LIST_MODE = 'ENABLE_LIST_MODE';
export const DISABLE_LIST_MODE = 'DISABLE_LIST_MODE';
export const DISABLE_LIST_MODE_ALL_DATATYPES = 'DISABLE_LIST_MODE_ALL_DATATYPES';

export const SELECT_ALERT_RECORD = 'SELECT_ALERT_RECORD';
export const SNACKBAR_MAP_MESSAGE = 'SNACKBAR_MAP_MESSAGE';
export const SNACKBAR_MAP_CLOSE = 'SNACKBAR_MAP_CLOSE';

export const RESEARCH_GRID_COLUMNS_REORDER = 'RESEARCH_GRID_COLUMNS_REORDER';
export const RESEARCH_GRID_COLUMN_TOGGLE = 'RESEARCH_GRID_COLUMN_TOGGLE';
export const RESEARCH_GRID_HIDE_ALL_COLUMNS_TOGGLE = 'RESEARCH_GRID_HIDE_ALL_COLUMNS_TOGGLE';
export const RESEARCH_GRID_SHOW_ALL_COLUMNS_TOGGLE = 'RESEARCH_GRID_SHOW_ALL_COLUMNS_TOGGLE';
export const RESEARCH_GRID_GRID_VIEW_SET_DEFAULT = 'RESEARCH_GRID_GRID_VIEW_SET_DEFAULT';
export const RESEARCH_GRID_GRID_VIEW_REQUEST = 'RESEARCH_GRID_GRID_VIEW_REQUEST';
export const RESEARCH_GRID_GRID_VIEW_SUCCESS = 'RESEARCH_GRID_GRID_VIEW_SUCCESS';
export const RESEARCH_GRID_GRID_VIEW_ERROR = 'RESEARCH_GRID_GRID_VIEW_ERROR';
export const RESEARCH_GRID_GRID_VIEW_SELECT = 'RESEARCH_GRID_GRID_VIEW_SELECT';
export const RESEARCH_GRID_GRID_VIEW_DELETE_REQUEST = 'RESEARCH_GRID_GRID_VIEW_DELETE_REQUEST';
export const RESEARCH_GRID_GRID_VIEW_DELETE_SUCCESS = 'RESEARCH_GRID_GRID_VIEW_DELETE_SUCCESS';
export const RESEARCH_GRID_GRID_VIEW_DELETE_ERROR = 'RESEARCH_GRID_GRID_VIEW_DELETE_ERROR';
export const RESEARCH_GRID_GRID_VIEW_SAVE_REQUEST = 'RESEARCH_GRID_GRID_VIEW_SAVE_REQUEST';
export const RESEARCH_GRID_GRID_VIEW_SAVE_SUCCESS = 'RESEARCH_GRID_GRID_VIEW_SAVE_SUCCESS';
export const RESEARCH_GRID_GRID_VIEW_SAVE_ERROR = 'RESEARCH_GRID_GRID_VIEW_SAVE_ERROR';
export const RESEARCH_GRID_GRID_VIEW_UPDATE_REQUEST = 'RESEARCH_GRID_GRID_VIEW_UPDATE_REQUEST';
export const RESEARCH_GRID_GRID_VIEW_UPDATE_SUCCESS = 'RESEARCH_GRID_GRID_VIEW_UPDATE_SUCCESS';
export const RESEARCH_GRID_GRID_VIEW_UPDATE_ERROR = 'RESEARCH_GRID_GRID_VIEW_UPDATE_ERROR';
export const UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST';

export const DASHBOARD_MYBOOKMARKS_WIDGET_ERROR = 'DASHBOARD_MYBOOKMARKS_WIDGET_ERROR';
export const DASHBOARD_MYBOOKMARKS_WIDGET_REQUEST = 'DASHBOARD_MYBOOKMARKS_WIDGET_REQUEST';
export const DASHBOARD_MYBOOKMARKS_WIDGET_SUCCESS = 'DASHBOARD_MYBOOKMARKS_WIDGET_SUCCESS';

export const ENABLE_MAP_PARCELS = 'ENABLE_MAP_PARCELS';
export const DISABLE_MAP_PARCELS = 'DISABLE_MAP_PARCELS';
export const PARCEL_DATA_LOADED = 'PARCEL_DATA_LOADED';
export const PARCEL_DATA_FILTERED = 'PARCEL_DATA_FILTERED';

export const DETAIL_PARCEL_FETCH_REQUEST = 'DETAIL_PARCEL_FETCH_REQUEST';
export const DETAIL_PARCEL_FETCH_ERROR = 'DETAIL_PARCEL_FETCH_ERROR';
export const DETAIL_PARCEL_FETCH_SUCCESS = 'DETAIL_PARCEL_FETCH_SUCCESS';
export const DETAIL_PARCEL_ASSESSMENTS_FETCH_REQUEST = 'DETAIL_PARCEL_ASSESSMENTS_FETCH_REQUEST';
export const DETAIL_PARCEL_ASSESSMENTS_FETCH_ERROR = 'DETAIL_PARCEL_ASSESSMENTS_FETCH_ERROR';
export const DETAIL_PARCEL_ASSESSMENTS_FETCH_SUCCESS = 'DETAIL_PARCEL_ASSESSMENTS_FETCH_SUCCESS';
export const DETAIL_PARCEL_PROPERTIES_FETCH_REQUEST = 'DETAIL_PARCEL_PROPERTIES_FETCH_REQUEST';
export const DETAIL_PARCEL_PROPERTIES_FETCH_ERROR = 'DETAIL_PARCEL_PROPERTIES_FETCH_ERROR';
export const DETAIL_PARCEL_PROPERTIES_FETCH_SUCCESS = 'DETAIL_PARCEL_PROPERTIES_FETCH_SUCCESS';
export const DETAIL_PARCEL_PROPERTIES_SELECT = 'DETAIL_PARCEL_PROPERTIES_SELECT';
export const DETAIL_PARCEL_PROPERTIES_CLEAR = 'DETAIL_PARCEL_PROPERTIES_CLEAR';
export const DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_REQUEST = 'DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_REQUEST';
export const DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_SUCCESS = 'DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_SUCCESS';
export const DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_ERROR = 'DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_ERROR';
export const DETAIL_ASSESSMENT_TRANSACTIONS_CLEAR = 'DETAIL_ASSESSMENT_TRANSACTIONS_CLEAR';
export const SET_PARCEL_PROPERTIES_SUCCESS = 'SET_PARCEL_PROPERTIES_SUCCESS';

export const GET_PARCEL_PATH_START = 'GET_PARCEL_PATH_START';
export const GET_PARCEL_PATH_END = 'GET_PARCEL_PATH_END';
