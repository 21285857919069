import SparkMD5 from 'spark-md5';
import {
  SHOW_FILTERS,
  HIDE_FILTERS,
  HIDE_ALL_FILTERS,
  ENABLE_FILTER_DATATYPE,
  DISABLE_FILTER_DATATYPE,
  ENABLE_LEASE_EXPIRATION_FILTERS,
  ENABLE_LEASE_FILTERS,
  ENABLE_LISTING_FILTERS,
  ENABLE_SALE_FILTERS,
  ENABLE_RESET_MODE,
  DISABLE_RESET_MODE,
  ENABLE_RESET_ALL_MODAL,
  ENABLE_RESET_FILTER_MODAL,
  LIST_ORDER_CHANGE,
  REQUEST_FILTERED_RESULTS,
  RECEIVE_FILTERED_RESULTS,
  PARCEL_FILTER_SELECTED,
  PARCEL_FILTERS_RESET,
  DISABLE_ALL_FILTERS,
  DISABLE_ALL_FILTER_DATATYPES,
  PARCEL_DATA_FILTERED,
  REQUEST_SAVEDSEARCH_EXPORT,
  SAVEDSEARCH_EXPORT_ERROR,
  SAVEDSEARCH_EXPORT_COMPLETE,
  SAVEDSEARCH_EXPORT_SUCCESS,
} from '../../constants/actionTypes';
import { clearPolygons, enableMapParcels } from '../map/actions';
import {
  clearSelectedResultsAllDatatypes,
  toggleShowSelected
} from '../actions';
import api, { getHeaders } from '../../services/api';
import { handleError, receiveData,requestData, unauthorizedRequest } from '../../common/apiActions';

export function showFilters(datatype) {
  return {
    type: SHOW_FILTERS,
    datatype
  };
}

export function hideFilters(datatype) {
  return {
    type: HIDE_FILTERS,
    datatype
  };
}

export function hideAllFilters() {
  return {
    type: HIDE_ALL_FILTERS,
  };
}

export function enableDatatype(datatype) {
  return {
    type: ENABLE_FILTER_DATATYPE,
    datatype
  };
}

export function disableDatatype(datatype) {
  return {
    type: DISABLE_FILTER_DATATYPE,
    datatype
  };
}

export function enableLeaseExpirations(datatype, data) {
  return {
    type: ENABLE_LEASE_EXPIRATION_FILTERS,
    datatype,
    data
  };
}

export function enableLeases(datatype, data) {
  return {
    type: ENABLE_LEASE_FILTERS,
    datatype,
    data
  };
}

export function enableListings(datatype, data) {
  return {
    type: ENABLE_LISTING_FILTERS,
    datatype,
    data
  };
}

export function enableSales(datatype, data) {
  return {
    type: ENABLE_SALE_FILTERS,
    datatype,
    data
  };
}

export function resetFilters() {
  return {
    type: PARCEL_FILTERS_RESET
  };
}

export function disableAllDatatypes() {
  return (dispatch, getState) => {
    const state = getState();
    const { research: { showSelected: showSelected } } = state;
    if (showSelected) {
      dispatch(toggleShowSelected());
    }
    dispatch(clearSelectedResultsAllDatatypes());
    dispatch(clearPolygons());
    dispatch(resetFilters());
    dispatch(enableMapParcels());
    return dispatch({ type: DISABLE_ALL_FILTER_DATATYPES });
  };
}

export function disableAllFilters() {
  return (dispatch) => {
    dispatch(disableAllDatatypes);
    return dispatch({ type: DISABLE_ALL_FILTERS });
  };
}

export function enableResetMode() {
  return {
    type: ENABLE_RESET_MODE
  };
}

export function disableResetMode() {
  return {
    type: DISABLE_RESET_MODE
  };
}

export function enableResetAllModal() {
  return {
    type: ENABLE_RESET_ALL_MODAL
  };
}

export function enableResetFilterModal() {
  return {
    type: ENABLE_RESET_FILTER_MODAL
  };
}

export function orderColumn(datatype, key) {
  return (dispatch, getState) => {
    const previousOrder = getState().research.searches[datatype].orderBy;
    let direction = 'asc';
    const sortKey = key;

    if (previousOrder && previousOrder.key === key) {
      if (previousOrder.direction === 'asc') {
        direction = 'desc';
      } else if (previousOrder.direction === 'desc') {
        direction = null;
      }
    }
    
    return dispatch({
      type: LIST_ORDER_CHANGE,
      datatype,
      key,
      direction,
      sortKey
    });
  };
}

export function requestSearchResults(datatype, query, filters) {
  return {
    type: REQUEST_FILTERED_RESULTS,
    datatype,
    query,
    filters
  };
}

export function receiveSearchResults(datatype, results) {
  results = results || {};
  results.hits = results.hits || { total: 0, hits: [] };
  if (results.error) {
    console.error(
      `[SearchError.${datatype}]`,
      (results.error.caused_by || {}).reason || results.error
    );
  }
  return {
    type: RECEIVE_FILTERED_RESULTS,
    datatype,
    results: results.hits.hits,
  };
}

export function selectFilter(filters) {
  return {
    type:PARCEL_FILTER_SELECTED,
    filters
  };
}

export function updateParcelFilteredData(data, filterCount) {
  return {
    type: PARCEL_DATA_FILTERED,
    data,
    filterCount,
    hash: SparkMD5.hash(Object.keys(data).sort().join()),
  };
}

export function exportSavedSearch(savedSearch) {
  const datatypes = savedSearch.enabledDataTypes.filter((datatype) => datatype !== 'news' && datatype !== 'tims');
  return (dispatch) => {
    dispatch({ type: REQUEST_SAVEDSEARCH_EXPORT });
    const apiRequests = [];
    for (const datatype of datatypes) {
      const filteredQuery = savedSearch.query[datatype].query;
      const query = {
        size: 500,
        query: filteredQuery
      };
      const apiRequest = api.create('/_search', query, {
        headers: getHeaders({ 'Elasticsearch-Query-Index': datatype }),
      })
        .then((results) => {
          dispatch(receiveData(SAVEDSEARCH_EXPORT_SUCCESS, results, {
            datatype, 
            query: savedSearch.query[datatype], 
            filter: savedSearch.query[datatype].filter }));
        })
        .catch((response) => {
          if (response.status === 401) return dispatch(unauthorizedRequest());
          return dispatch(handleError(SAVEDSEARCH_EXPORT_ERROR, response || new Error('unknown error')));
        });
      apiRequests.push(apiRequest);
    }
    Promise.all(apiRequests)
      .then(() => dispatch(requestData(SAVEDSEARCH_EXPORT_COMPLETE)))
      .catch((error) => dispatch(handleError(SAVEDSEARCH_EXPORT_ERROR, error)));
  };
}
